
















import { Component, Vue, Watch } from "vue-property-decorator";
import TopBar from "@/components/TopBar.vue";
import { api } from "@/api";

const TABS = [
  { label: "Accounts", to: "/analytics/accounts" },
  { label: "YouTube Channels", to: "/analytics/youtube" },
  { label: "Creators & Managers", to: "/analytics/creators" },
];

@Component({
  components: {
    TopBar,
  },
})
export default class Analytics extends Vue {
  public iframeUrl = null;
  public tabs = TABS;

  @Watch("$route.params", { immediate: true, deep: true })
  public async refreshDashboardUrl() {
    this.iframeUrl = (
      await api.getMetabaseIframeUrl(this.$route.params.type)
    ).data;
  }

  public beforeRouteEnter(to, _, next) {
    if (!TABS.map(({ to }) => to).includes(to.path)) {
      next({ path: TABS[0].to });
    }
    next();
  }

  public mounted() {
    window["analytics"]["page"]("/analytics");
  }
}
